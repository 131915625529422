<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 작업기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-2y"
            defaultEnd="2M"
            label="공고기간"
            name="pntcPeriod"
            v-model="searchParam.pntcPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="SAI_LAW_MAKING_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="saiLawMakingStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.saiLawMakingStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검토자 -->
          <c-field
            label="LBLREVIEWER"
            name="checkUserId"
            v-model="searchParam.checkUserId" />
        </div>
      </template>
    </c-search-box>
    <c-table
      title="입법예고검토 목록"
      tableId="lawMakingCheck"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row.fileDownLink">
          <q-btn
            class="tableinnerBtn"
            flat
            icon="launch"
            align="center"
            color="blue-6"
            label=""
            @click.stop="linkClick(props.row, col)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'law-making-review',
  data() {
    return {
      searchParam: {
        pntcPeriod: [],
        saiLawMakingStepCd: null,
        checkUserId: '',
      },
      grid: {
        columns: [
          {
            name: 'saiLawMakingStepName',
            field: 'saiLawMakingStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'link',
          },
          {
            name: 'acceptDeptNames',
            field: 'acceptDeptNames',
            label: '적용부서',
            align: 'left',
            sortable: false,
            style: 'width:130px',
          },
          {
            name: 'checkUserNames',
            field: 'checkUserNames',
            label: 'LBLREVIEWER',
            align: 'left',
            sortable: false,
            style: 'width:130px',
          },
          {
            name: 'isNm',
            field: 'isNm',
            label: '입법예고명',
            align: 'left',
            sortable: false,
            style: 'width:350px',
            type: 'link',
          },
          {
            name: 'custom',
            field: 'custom',
            label: '법령안',
            align: 'center',
            sortable: false,
            style: 'width:50px',
            type: 'custom'
          },
          {
            name: 'isClsAsndOfiNm',
            field: 'isClsAsndOfiNm',
            label: '법령종류(소관부처)',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'pntc',
            field: 'pntc',
            label: '공고번호(공고일자)',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'period',
            field: 'period',
            label: 'LBLPERIOD',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.lawMaking.check.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      if (col.name === 'saiLawMakingStepName') {
        this.popupOptions.title = '입법예고 검토';
        this.popupOptions.param = {
          mdmLawMakingCheckId: row.mdmLawMakingCheckId,
          mdmLawMakingId: row.mdmLawMakingId,
          row: row,
        };
        this.popupOptions.target = () => import(`${'./lawMakingReviewDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (col.name === 'isNm') {
        window.open(row.detailUrl, '입법예고명', 'width=1200, height=700');
      } else if (col.name === 'custom') {
        let link = document.createElement('a');
        link.href = row.fileDownLink;
        link.download = row.fileName;
        link.click();
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
